









































import { computed, defineComponent, ref } from '@vue/composition-api';
import { useTalkRoomMaster } from '@/admin/talkRoom';
import { useNotification } from '@/composition/notification';
import { useConfirm } from '@/composition/confirm';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';

export default defineComponent({
  name: 'TalkRoomSetting',
  components: {
    FcRoleLoading,
  },
  setup() {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('talkrooms'));

    const { talkRoomMaster } = useTalkRoomMaster();
    const notification = useNotification();
    const { confirmDialog } = useConfirm();
    const isSaving = ref(false);
    const save = async () => {
      if (!(await confirmDialog('本当に保存しますか？'))) return;
      isSaving.value = true;
      try {
        await talkRoomMaster.saveSetting();
        notification.notify('更新しました。');
      } catch (error) {
        notification.error(error);
      }
      isSaving.value = false;
    };

    return {
      pageTitle: 'トークルームマスター',
      myRoleSettings,
      talkRoomMaster,
      save,
      isSaving,
    };
  },
});
